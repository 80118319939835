@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Geist';
  src: url('./assets/fonts/PublicSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./assets/fonts/PublicSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./assets/fonts/PublicSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geist';
  src: url('./assets/fonts/PublicSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Geist';
  src: url('./assets/fonts/PublicSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Geist';
}

html {
  font-size: 62.5%; /* if defaults browser font-size is 16 then  1rem = 10px  */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Geist', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
}

.ant-tree-switcher {
  display: none;
}

.ant-upload.ant-upload-drag {
  height: 15rem;
}

.ant-tree {
  background: inherit !important;
  color: inherit !important;
  font-size: 20px !important;
}

.ant-tree .ant-tree-treenode {
  padding: 0.4rem 1.6rem !important;
  border-bottom: 0.5px solid rgba(141, 141, 141, 0.2) !important;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #eff1fe !important;
  height: 100%;
}

.rpv-core__page-layer,
.rpv-core__canvas-layer,
canvas {
  width: 100% !important;
}

.ant-tree-title {
  word-break: break-word;
}

.ant-upload-drag-icon {
  display: flex;
  justify-content: center;
}

.darkBackground {
  background: black !important;
}
.lightBackground {
  background: white !important;
}

.ant-upload-list {
  display: none !important;
}

.ant-tree .ant-tree-node-content-wrapper {
  line-height: 25px !important;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  height: 25px !important;
  line-height: 25px !important;
}

.ant-tree.ant-tree-directory
  .ant-tree-treenode
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: black !important;
}

.ant-tree-treenode:hover {
  color: black !important;
}

.ant-tree .ant-tree-node-content-wrapper,
.ant-tree-draggable-icon {
  min-height: 25px !important;
  line-height: 25px !important;
}

.MuiTypography-caption,
.MuiPickersDay-root {
  /* DateTimePicker */
  font-size: 1.2rem !important;
}

.spinner {
  border-radius: 50%;
  animation: spin-anim 4s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  /* Override transform for expanded state */
  transform: rotate(0deg) !important; /* Replace with your expanded transform */
}

.MuiAccordionSummary-content.Mui-expanded
  .MuiStack-root
  .MuiButtonBase-root.MuiButton-root.AccordionExpandIcon {
  transform: rotate(180deg) !important;
}

.rotate180deg {
  transform: rotate(180deg) !important;
}

.ant-tree-draggable-icon {
  display: none !important;
}

.ant-tree-list {
  margin: 0 !important;
}

.MuiList-root.MuiMenu-list {
  padding: 0;
}

.ant-tree-title {
  display: inline-block;
  text-align: center;
  vertical-align: -webkit-baseline-middle;
  flex: 1;
}

.ant-tree-node-content-wrapper {
  display: flex;
}
