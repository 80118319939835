.App {
  text-align: center;
}

.test {
  display: block;
}

/* unvisited link */
a:link {
  color: #fff;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #fff;
}

/* mouse over link */
a:hover {
  color: #fff;
}

/* selected link */
a:active {
  color: #fff;
}

.link {
  display: flex;
  align-items: center;
}

img {
  width: 100%;
}

.boldText {
  font-weight: 800;
}

.pointerCursor {
  cursor: pointer;
}

.test-class-will-be-deleted {
  display: flex;
}

.icon20 {
  width: 2rem;
  height: 2rem;
}

.icon21 {
  width: 24px;
  height: 24px;
}
.icon12 {
  width: 1.2rem;
  height: 1.2rem;
}

.markdown-image {
  max-width: 70rem !important;
  max-height: 40.4rem !important;
}

.dot {
  border-radius: 200px;
  width: .8rem;
  height: .8rem;
}

.container__borderAndShadow {
  border-radius: 5px;
  border: 1px solid var(--DSM_Gray-02, #E5EBF2);
  background: #FFF;

/* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

// App.scss
.desktop-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
}
.time-box {
  display: flex;
  padding: 6px 16px;
  align-items: flex-start;
  gap: 10px;
  font-size: 13px;
  font-weight: 400;
}

.selected {
  background: #fff;
  color: #364451;
  border-radius: 3px;
}

.unselected {
  background: transparent;
  color: #667085;
  cursor: pointer;
}

.time-box-parent {
  display: flex;
  padding: 5px;
  align-items: flex-start;
  border-radius: 6px;
  background: #F8F4FF;
  width: fit-content;
  margin-left: auto;
}
.chart_section_title {
color: #364451;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal
}

